@media only screen and (min-width: 600px) {
  body {
    background-image: url('../public/Push_Forward_Pousse_Album_Cover_web.png');
    background-attachment: fixed;
    background-size: cover;
    height: 100%;
    margin: 10;
  }

  .albumCover {
    width: 50%;
    max-width: 400px;
  }

  .bgimg1, .bgimg2, .bgimg3, .bgimg4, .bgimg5 {
    position: relative;
    opacity: 0.95;
    background-position: center;
    background-repeat: repeat-x;
    background-size: cover;
  }
  
  .bgimg1 {
    background-image: url('../public/HaeraLogoTextLight68Hue324.png');
    min-height: 400px;
    background-color: rgb(230, 225, 219);
  }
  
  .bgimg2 {
    background-image: url('../public/Squirtle-Squad-dark.jpg');
    min-height: 400px;
  }
  
  .bgimg3 {
    background-image: url('../public/PeopleArenaOutsideEsplanadeDark.jpg');
    min-height: 400px;
  }

  .bgimg4 {
    background-image: url('../public/Push_Forward_fbcover_C_small.png');
    min-height: 400px;
  }

  .bgimg5 {
    background-image: url('../public/InstaSetList.png');
    min-height: 400px;
  }

  .yannBg {
    background-image: url('../public/YannGif.gif');
    background-size: cover;
    min-height: 700px;
    background-repeat: no-repeat;
  }

  .andrewBg {
    background-image: url('../public/AndrewGif.gif');
    background-size: cover;
    min-height: 700px;
    background-repeat: no-repeat;
  }

  .bitoBg {
    background-image: url('../public/Bito_eyes.gif');
    background-size: cover;
    min-height: 700px;
    background-repeat: no-repeat;
    background-position: center;
  }

  .signatureContainer {
    position: relative;
    width: 100%;
    height: 14em;
  }

  .signature {
    position: absolute;
    bottom: 0;
    left: 5%;
  }

  .caption {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    text-align: center;
    color: #000;
  }
}

.albumCover {
  width: 80%;
}

.center {
  display: flex; 
  width: 100vw;
  justify-content: center;
  align-items: center;
}

.autoScaleImage {
  max-width: 100%;
  height: auto;
}

.youtubeCarrouselButtonL {
  left: 3%;
}

.youtubeCarrouselButtonR {
  right: 3%;
}

.youTubeOverlay {
  position: absolute;
  padding-top: 65px;
  pointer-events: none
}

body {
  background-image: url('../public/Push_Forward_Pousse_Album_Cover_web.png');
  background-size: cover;
  height: 100%;
  margin: 10;
}

.yannBg {
  background-image: url('../public/YannGif.gif');
  background-size: contain;
  min-height: 400px;
  background-repeat: no-repeat;
  background-position: center;
}

.andrewBg {
  background-image: url('../public/AndrewGif.gif');
  background-size: contain;
  min-height: 400px;
  background-repeat: no-repeat;
  background-position: center;
}

.bitoBg {
  background-image: url('../public/Bito_eyes.gif');
  background-size: contain;
  min-height: 400px;
  background-repeat: repeat;
  background-position: center;
}

.banner > .streamMusicButton {
  margin: 2px;
}

.middle {
  margin-top: 70%;
}

.personalContent {
  padding-left: 2%;
  padding-bottom: 2%;
}

.bgimg1, .bgimg2, .bgimg3, .bgimg4, .bgimg5 {
  position: relative;
  opacity: 0.85;
  background-position: center;
  background-repeat: repeat-x;;
  background-size: contain;
}

.contentW {
  color: whitesmoke;
  opacity: 0.80;
  background-color: #636363;
}

.bgimg3alt {
  padding: 4%;
  position: relative;
  opacity: 0.85;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../public/PeopleArenaOutsideEsplanadeDark.jpg');
}

.bgimg1 {
  background-image: url('../public/HaeraLogoTextLight68Hue324_small.png');
  min-height: 200px;
  background-color: rgb(230, 225, 219);
}

.bgimg2 {
  background-image: url('../public/Squirtle-Squad-dark.jpg');
  min-height: 200px;
}

.bgimg3 {
  background-image: url('../public/PeopleArenaOutsideEsplanadeDark.jpg');
  min-height: 200px;
}

.bgimg4 {
  background-image: url('../public/Push_Forward_fbcover_C_small.png');
  min-height: 200px;
}

.bgimg5 {
  background-image: url('../public/InstaSetList.png');
  min-height: 200px;
}

.banner {
  color: white;
  background-color: #999797;
  text-align: center;
  padding: 2% 6%;
  opacity: 0.99;
}

.bannerMusic {
  color: white;
  background-color: #999797;
  text-align: center;
  padding: 1% 6%;
  opacity: 0.99;
}

.bannerGradient {
  color: #09454f;
  background-color: #636363;
  background-image: linear-gradient(to right, #f2f1ec, #636363);
  text-align: center;
  padding: 2% 6%;
  opacity: 0.95;
}

.bannerLight {
  color: #ddd;
  background-color: #636363;
  text-align: center;
  padding: 2% 6%;
  opacity: 0.95;
}

.bannerLightGradient {
  color: #ddd;
  background-color: #636363;
  background-image: linear-gradient(to right, #f2f1ec, #636363);
  text-align: center;
  padding: 2% 6%;
  opacity: 0.75;
}

.bannerMessage {
  background-color: transparent;
  font-size: 1em;
  font-weight: bold;
  color: white;
  display: block;
}

.bannerMessageB {
  background-color: transparent;
  font-size: 1em;
  font-weight: bold;
  color: black;
  display: block;
}

.caption .bannerIcon {
  font-size: 4em;
  display: block;
  margin: auto;
  color: white;
}

.caption .bannerIconB {
  font-size: 4em;
  display: block;
  margin: auto;
  color: black;
}

.caption .bannerIconRed {
  font-size: 4em;
  display: block;
  margin: auto;
  color: #fe0000;
}

.caption .bannerIconBlue {
  font-size: 4em;
  display: block;
  margin: auto;
  color: #1878f3;
}

.caption {
  position: absolute;
  left: 0;
  top: 25%;
  width: 100%;
  text-align: center;
  color: #000;
}

.captionIconSpan {
  display: inline;
  margin-right: 5px;
}

.container {
  font-family: 'Red Hat Text', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 1em;
  max-width: 100%;
}

.footer {
  justify-content: center;
  background-color: #636363;
}

.footer > a {
  padding-right: 1%;
}

.nav {
  background-color: black;
}

.navIcon {
  margin-bottom: -5%;
}

.nav.navItems {
  font-family: 'Red Hat Text', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 1em;
  padding-right: 0.4em;
}

.nav.navItems.navLeft {
  position: absolute;
  right: 0;
  padding-right: 1em;
  cursor: pointer;
}

.noDeco {
  color: whitesmoke;
}

.noDecoBlue {
  color: #09454f;
}

.noDecoB {
  color: black;
}

.linkPop {
  color: #eeff02;
}

.noDeco:link, .noDecoBlue:link, .noDecoB:link, .linkPop:link {
  text-decoration: none;
}

.noDeco:hover, .noDecoB:hover, .noDecoBlue:hover {
  text-decoration: none;
  color: #eeff02;
  cursor: pointer;
}

.noDeco:active, .noDecoB:active, .linkPop:active, .noDecoBlue:active {
  text-decoration: none;
}

.noDeco:visited, .noDecoB:visited, .linkPop:visited, .noDecoBlue:visited {
  text-decoration: none;
}

.storeMain {
  min-height: 650px;
}
